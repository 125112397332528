















import { Component, Vue, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import suplementoModule from "@/store/modules/visualizacion_dieta/suplemento-module";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {
    ViewDataEstructuraBasica: () =>
      import(
        "@/views/entrevista/dieta-visualizacion/view_data_estructura_basica.vue"
      ),
  },
})
export default class ViewDataSuplemento extends Vue {
  @PropSync("sup", { type: Object, default: {} })
  syncedsuplemento!: dieta_visualizacion;

  public created() {
    suplementoModule.getsuplementoficha(this.syncedsuplemento.id_estructura);
  }

  public get suplemento() {
    return suplementoModule.suplemento_ficha;
  }
  public edit_suplemento() {
    this.$router.push({
      name: RouterNames.suplementoficha,
      params: { id: this.suplemento.id_estructura.toString() },
    });
  }
}
